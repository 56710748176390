import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "../src/components/Hero";
import Footer from "../src/components/Footer";

function App() {
  return (
    <div>
      <Hero />
      {/* <Tablecomponent /> */}
      <Footer />
    </div>
  );
}

export default App;
